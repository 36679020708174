import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Stack } from '@mui/material';
import {
  requestStatus,
  eventRequestTypes,
  platformSelectors,
  fetchCustomAttributes,
  fetchContentRepositories,
  useTableData,
  useEventReports,
  useGetActiveTool,
  useDeepCompareMemo,
  getFilterByToolOptions,
  getChartColorsForToolSlug,
  getUserCustomAttributeData,
  generateGroupByOptionsWithAdditionalProps,
  generateFilterByContentRepositoriesOptions,
} from '@clatter/platform';
import {
  ReportsChart,
  ReportToolsCards,
  ReportGroupsSection,
  ReportFiltersSection,
  ReportDetailsSection,
  FiltersSectionWrapper,
} from '@clatter/ui';

const detailsReportWhatColumns = [
  {
    headerName: 'Slide Name',
    field: 'slide_name',
    sortable: true,
    flex: 1,
  },
  {
    headerName: 'Content Repository Name',
    field: 'content_repository_name',
    sortable: true,
    flex: 1,
  },
  {
    headerName: '# of Downloads',
    field: 'count',
    sortable: true,
    flex: 1,
  },
];

const REPORT_TITLE = 'Slides Downloads';

export const SlidesDownloadsReport = ({ usersByEmailMap, documentFileName, isLoading: isParentLoading, isAdmin = false }) => {
  const dispatch = useDispatch();
  const activeTool = useGetActiveTool();
  const activeToolSlugUppercase = activeTool ? activeTool?.slug?.toUpperCase() : null;
  const provisionedTools = useSelector(platformSelectors.tools.selectToolsNamesMap());

  const userCustomAttributes = useSelector(platformSelectors.userCustomAttributes.selectAllUserAttributes);
  const filteredToolsNames = useSelector((state) =>
    platformSelectors.tools.selectToolsNamesMapByKeys(state, ['pg', 'sl']),
  );
  const contentRepositoriesList = useSelector(
    platformSelectors.contentRepositories.selectAllContentRepositories,
  ).filter((contentRepo) => contentRepo.enable);

  const userCustomAttributesOptions = getUserCustomAttributeData(userCustomAttributes);
  const contentRepositoriesOptions = generateFilterByContentRepositoriesOptions(contentRepositoriesList);

  const { toolsNamesMap, filterByToolOptions } = getFilterByToolOptions({
    toolsNames: filteredToolsNames,
    includeHome: false,
  });

  const enableToolPicker = useDeepCompareMemo(
    () => isAdmin || (provisionedTools?.hasOwnProperty('sl') && provisionedTools?.hasOwnProperty('pg')),
    [provisionedTools],
  );

  const reportFiltersInputs = useDeepCompareMemo(
    () => [
      // filter by tool
      enableToolPicker && {
        key: 'tool',
        type: 'select',
        isMultiSelect: true,
        placeholder: 'Select tool(s) or leave blank to show data for all tools',
        defaultOptionValue: 'all',
        options: [
          {
            label: 'All',
            value: 'all',
          },
          ...filterByToolOptions,
        ],
        label: 'Tool: ',
        getChipColors: getChartColorsForToolSlug,
      },
      // filter by content repository
      {
        key: 'content_repository',
        type: 'select',
        label: 'Content Repository: ',
        maxWidth: '200px',
        isMultiSelect: false,
        options: [
          {
            label: 'Any',
            value: 'none',
          },
          ...contentRepositoriesOptions,
        ],
      },

      // filter by UCA
      ...(userCustomAttributesOptions.filtersInputs || []),

      // filter by source
      {
        key: 'download_source',
        type: 'select',
        label: 'Download Source: ',
        maxWidth: '200px',
        isMultiSelect: true,
        options: [
          {
            label: 'All',
            value: 'all',
          },
          {
            label: 'Presentation',
            value: 'presentation',
          },
          {
            label: 'Deck',
            value: 'deck',
          },
          {
            label: 'Slide',
            value: 'slide',
          },
        ],
      },
    ],
    [userCustomAttributesOptions, contentRepositoriesOptions, enableToolPicker],
  );
  const {
    isLoading: isReportLoading,
    selectedFilterBy,
    totalReportData,
    totalReportByGroupData,
    reportData,
    detailsReportData,
    selectedToolsWithTotal,
    handleFilterByChange,
    handleGroupByChange,
  } = useEventReports({
    eventType: eventRequestTypes.slideDownload,
    label: REPORT_TITLE,
    filterByInputs: reportFiltersInputs,
    toolsNamesMap: enableToolPicker ? toolsNamesMap : null,
    fetchTotal: enableToolPicker ? {} : { filterBy: { tool: activeToolSlugUppercase } },
    fetchTotalByGroups: {
      ...(enableToolPicker ? {} : { filterBy: { tool: activeToolSlugUppercase } }),
      groupByAdditionalFields: ['content_repository_name'],
    },
    fetchByDay: enableToolPicker ? {} : { filterBy: { tool: activeToolSlugUppercase } },
    fetchDetails: {
      ...(enableToolPicker ? {} : { filterBy: { tool: activeToolSlugUppercase } }),
      who: {
        groupBy: 'user_email',
        groupByAdditionalFields: ['last_login', 'user_custom_attributes'],
      },
      what: {
        groupBy: 'slide',
        groupByAdditionalFields: ['content_repository_name'],
      },
    },
  });

  const { usersRows, usersColumns } = useTableData({
    usersReportData: detailsReportData?.who,
    usersByEmailMap: usersByEmailMap,
    usersExtraColumns: [
      {
        headerName: '# of Downloads',
        field: 'count',
        sortable: true,
        flex: 1,
      },
    ],
  });

  const detailsReportWhatRows = useDeepCompareMemo(() => {
    if (!detailsReportData?.what) {
      return [];
    }

    return detailsReportData?.what?.map((detailReportDataRow, detailReportDataIndex) => ({
      id: detailReportDataIndex + 1,
      slide_name: detailReportDataRow._id.slide,
      content_repository_name: detailReportDataRow.content_repository_name,
      count: detailReportDataRow.count,
    }));
  }, [detailsReportData?.what]);

  const detailsReportTableData = useDeepCompareMemo(() => {
    return {
      tabs: [
        {
          label: 'Who',
        },
        {
          label: 'What',
        },
      ],
      tabsData: [
        {
          rows: usersRows,
          columns: usersColumns,
          exportFileName: `${documentFileName} - Who`,
          tableName: 'report/slides-downloads-who',
        },
        {
          rows: detailsReportWhatRows,
          columns: detailsReportWhatColumns,
          exportFileName: `${documentFileName} - What`,
          tableName: 'report/slides-downloads-what',
        },
      ],
    };
  }, [documentFileName, usersRows, usersColumns]);

  //region FILTERS / GROUPS
  const isFiltersDataLoading =
    useSelector(platformSelectors.userCustomAttributes.loadingStatus) === requestStatus.pending;

  const reportGroupByInputs = useDeepCompareMemo(
    () =>
      generateGroupByOptionsWithAdditionalProps({
        options: [
          {
            label: 'Content Repository',
            value: 'content_repository',
          },
          {
            label: 'Download Source',
            value: 'download_source',
          },
          ...(userCustomAttributesOptions.groupByOptions || []),
        ],
        filterByInputs: reportFiltersInputs,
        selectedFilterBy: selectedFilterBy,
      }),
    [userCustomAttributes, userCustomAttributesOptions, reportFiltersInputs, selectedFilterBy],
  );

  const isLoading = isParentLoading || isReportLoading;
  //endregion

  //region EFFECTS
  useEffect(() => {
    dispatch(fetchCustomAttributes());
    dispatch(fetchContentRepositories());
  }, [dispatch]);
  //endregion

  return (
    <>
      {!isFiltersDataLoading && (
        <FiltersSectionWrapper
          components={[
            <ReportFiltersSection
              onChange={handleFilterByChange}
              inputs={reportFiltersInputs}
              isLoading={isLoading}
              showDateRangePicker
              labelMinWidth={130}
            />,
            <ReportGroupsSection
              selectedFilterBy={selectedFilterBy}
              onChange={handleGroupByChange}
              inputs={reportGroupByInputs}
              isLoading={isLoading}
            />,
          ]}
        />
      )}

      <Stack direction="column" spacing={2}>
        <ReportToolsCards totalReportData={totalReportData} />

        <ReportsChart
          title={REPORT_TITLE}
          reportData={reportData}
          selectedTools={selectedToolsWithTotal}
          loading={isLoading}
        />

        {totalReportByGroupData && (
          <ReportDetailsSection
            loading={isLoading}
            tableName="report/total-slides-downloads-by-groups"
            exportFileName={`${documentFileName} - Total ${REPORT_TITLE} by Groups`}
            columns={[
              {
                headerName: 'Download Source',
                field: 'name',
                sortable: true,
                flex: 1,
              },
              {
                headerName: '# of Downloads',
                field: 'count',
                sortable: true,
                flex: 1,
              },
            ]}
            rows={totalReportByGroupData}
          />
        )}

        <ReportDetailsSection loading={isLoading} exportFileName={documentFileName} tabsData={detailsReportTableData} />
      </Stack>
    </>
  );
};
